import { LoaderFunctionArgs, redirect } from '@remix-run/node';
import { withAugmentedContext } from '~/services/auth/Guards';
import { FormInput } from '~/components/form-input';
import { validationError } from 'remix-validated-form';
import { z } from 'zod';
import { withZod } from '@remix-validated-form/with-zod';
import { Link } from '@remix-run/react';
import { Routes } from '~/constants/routes';

import { AuthContainer } from '~/components/auth-container';
import { env } from '~/constants/env';

const loginFormSchema = z.object({
  email: z.string().min(1, 'Email is required').email(),
  password: z.string().min(1, 'Password is required'),
});

const loginFormValidator = withZod(loginFormSchema);

export const loader = withAugmentedContext(
  async ({ context, request }: LoaderFunctionArgs) => {
    const { user } = context;

    if (user) {
      return redirect(Routes.Deployments);
    }

    await context.sessionManager.deleteSession(request.headers.get('Cookie'));
    return null;
  },
);

export const action = withAugmentedContext(async ({ context, request }) => {
  const cookie = request.headers.get('Cookie');

  const validationResult = await loginFormValidator.validate(
    await request.formData(),
  );

  if (validationResult.error) {
    return validationError(validationResult.error);
  }

  const { accessToken, success, mfaToken } =
    await context.auth0.getAccessTokenFromPasswordFlow({
      username: validationResult.data.email,
      password: validationResult.data.password,
    });

  if (!success) {
    return validationError({
      fieldErrors: {
        password: 'Invalid email or password',
      },
    });
  }

  if (mfaToken) {
    await context.sessionManager.patchAuthData(cookie, {
      mfaToken,
      email: validationResult.data.email,
    });
    const hasMfaEnrolled =
      await context.auth0.mfaClient.doesUserHaveMfaEnrolled(mfaToken);
    const cookieHeader = await context.sessionManager.patchAuthData(cookie, {
      email: validationResult.data.email,
      mfaToken,
    });

    if (!hasMfaEnrolled) {
      await context.api.PATCH('/signup/setup-auth', {
        body: {
          email: validationResult.data.email,
        },
      });
    }

    return redirect(
      hasMfaEnrolled ? Routes.ConfirmTwoFactor : Routes.SetupTwoFactor,
      {
        headers: { 'Set-Cookie': cookieHeader },
      },
    );
  }

  const { data: userInfo, error: userError } = await context.api.GET('/users', {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (userError || !accessToken) {
    console.error(userError);
    return redirect(Routes.Error);
  }

  const cookieHeader = await context.sessionManager.saveUserSession(cookie, {
    ...userInfo?.user,
    accessToken,
  });

  return redirect(Routes.Deployments, {
    headers: { 'Set-Cookie': cookieHeader },
  });
});

export default function Login() {
  return (
    <AuthContainer
      buttonLabel="Login"
      buttonType="submit"
      contentTitle="Login"
      contentDescription="Welcome back! Provide your user login details."
      formValidator={loginFormValidator}
      footer={
        <div className="flex justify-between text-sm">
          <Link
            to={Routes.ForgotPassword}
            className="underline text-teal-light"
          >
            Forgot Password
          </Link>
          <p>
            Don't have an account?{' '}
            <Link to={Routes.SignUp} className="text-teal-light">
              Sign up
            </Link>
          </p>
        </div>
      }
    >
      <FormInput name="email" label="Email" placeholder="Your Email" />
      <FormInput
        name="password"
        label="Password"
        type="password"
        className="mt-10"
        placeholder="Your Password"
      />
    </AuthContainer>
  );
}
